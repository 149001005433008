import {constructFromInterface} from 'app/core/logic/map.logic';
import {User} from '../entity/user';
import {translate} from "@jsverse/transloco";
import {marker} from "@nyffels/transloco-keys-manager/marker";
import {GetDateTimeScalar, SetDateTimeScalar} from "../../../core/logic/date-scalars";
import {IDeliveredDocument} from "../../../core/graphql/generated/types";

export class DeliveredDocument implements IDeliveredDocument {
  id: number = 0;
  subscriberId: number = 0;
  userId: number = 0;
  user: User;
  fileName: string = '';
  type: OcrTypeEnum = OcrTypeEnum.PURCHASEINVOICE;
  documentType: OcrDocumentType = OcrDocumentType.PDF;
  status: OcrStatusEnum = OcrStatusEnum.QEUE;
  result: string = '';
  completed: string = "";
  private _completed: Date;

  get Completed() {return this._completed;}

  set Completed(date: Date) {
    this._completed = date;
    this.completed = SetDateTimeScalar(date);
  }

  created: string = "";

  private _created: Date;

  get Created() {return this._created;}

  set Created(date: Date) {
    this._created = date;
    this.created = SetDateTimeScalar(date);
  }

  source: OcrSourceEnum = OcrSourceEnum.SYSTEM;
  raw: string = "";

  get Result() {
    try {
      return JSON.parse(this.result?.replace(/(?:\r\n|\r|\n)/g, "\\n"));
    } catch (ex) {
      console.error(ex);
      return this.result;
    }
  }

  get Status(): string {
    switch (this.status) {
      case OcrStatusEnum.QEUE:
        return translate(marker("status.ocr.qeue", "In wacht"));
      case OcrStatusEnum.PROCESSING:
      case OcrStatusEnum.PROCESSINGEXTERNAL:
        return translate(marker("status.ocr.processing", "Verwerken"));
      case OcrStatusEnum.COMPLETED:
        return translate(marker("status.ocr.completed", "Uitgelezen"));
      case OcrStatusEnum.FAILED:
        return translate(marker("status.ocr.failed", "Mislukt"));
      case OcrStatusEnum.FINISHED:
        return translate(marker("status.ocr.finished", "Afgerond"));
      default:
        return translate(marker("status.ocr.qeue", "In wacht"));
    }
  }

  get Type() {
    switch (this.type) {
      case OcrTypeEnum.PURCHASEINVOICE:
        return translate(marker('type.ocr.purchaseinvoice', 'Aankoopfactuur'))
      case OcrTypeEnum.PURCHASECREDITNOTE:
        return translate(marker('type.ocr.purchasecreditnote', 'Aankoop creditnota'))
    }
  }

  get DocumentType() {
    switch (this.documentType) {
      case OcrDocumentType.PDF:
        return translate(marker('type.document.pdf', "Pdf"));
      case OcrDocumentType.UBL:
        return translate(marker('type.document.ubl', 'Ubl'));
    }
  }

  constructor(deliveredDocument: IDeliveredDocument = null) {
    if (deliveredDocument === null) {
      return null;
    }

    constructFromInterface(this, deliveredDocument);

    if (deliveredDocument.completed) {this._completed = GetDateTimeScalar(deliveredDocument.completed);}
    if (deliveredDocument.created) {this._created = GetDateTimeScalar(deliveredDocument.created);}
    if (deliveredDocument.user) {
      this.user = new User(deliveredDocument.user);
    }
  }
}

export enum OcrTypeEnum {
  PURCHASEINVOICE = 1,
  PURCHASECREDITNOTE = 2,
}

export enum OcrStatusEnum {
  QEUE = 1,
  PROCESSING = 2,
  PROCESSINGEXTERNAL = 3,
  COMPLETED = 4,
  FAILED = 5,
  FINISHED = 6,
}

export enum OcrSourceEnum {
  SYSTEM = 1,
  EMAIL = 2,
  PEPPOL = 3
}

export enum OcrDocumentType {
  UBL = 1,
  PDF = 2,
}
