import {Address} from 'app/shared/models/relation/address';
import {Company} from 'app/shared/models/entity/company';
import _ from 'lodash';
import {Transaction} from 'app/shared/models/general/transaction';
import {Email} from 'app/shared/models/mail/email';
import {Translocation} from 'app/shared/models/general/translocation';
import {Country} from 'app/shared/models/general/country';
import {WorkPerformance} from 'app/shared/models/document/workperformance';
import moment from 'moment-timezone';
import {Subscription} from './subscription';
import {Service} from 'app/shared/models/article/service';
import {ArticleTypeEnum} from 'app/shared/models/article/articletype.enum';
import {Product} from '../article/product';
import {Relation} from '../relation/relation.class';
import {AccountNumber} from '../ledger/account-number';
import {User} from '../entity/user';
import {Document} from '../general/document';
import {LedgerExportTypeEnum} from '../ledger/ledger-export';
import {CombinedArticle} from '../article/combinedarticle';
import {Contact} from '../relation/contact';
import {InvoiceReminder} from './invoice-reminder';
import {Peppol} from '../mail/peppol';
import {constructFromInterface} from 'app/core/logic/map.logic';
import {Journal} from '../ledger/journal';
import {translate} from '@jsverse/transloco';
import {marker} from '@nyffels/transloco-keys-manager/marker';
import {VatService} from "../../../core/services/vat.service";
import {getWhitelabelEnvironment} from "../../../core/logic/whitelabel.logic";
import {ICombinedArticle, IInvoice, IInvoiceInput, IInvoiceParameter, IInvoiceParameterInput, IInvoiceSubline, IInvoiceSublineInput, IProduct, IService} from "../../../core/graphql/generated/types";
import {GetDateScalar, GetDateTimeScalar, SetDateScalar, SetDateTimeScalar} from "../../../core/logic/date-scalars";
import {countries} from "../../data/country";


export class Invoice implements IInvoice {
  id: number = 0;
  subscriberId: number = 0;
  number: number = 0;
  Number: string = '';
  orderId: number = 0;
  quoteId: number = 0;
  companyId: number = 0;
  companyName: string = '';
  companyVatNumber: string = '';
  companyAddressStreet: string = '';
  companyAddressNumber: string = '';
  companyAddressZip: string = '';
  companyAddressCity: string = '';
  companyAddressCounty: string = '';
  companyAddressCountryId: number = 0;
  companyAddressCountry: Country;

  get company_address(): Address {
    return new Address({
      id: null,
      street: this.companyAddressStreet,
      number: this.companyAddressNumber,
      city: this.companyAddressCity,
      zip: this.companyAddressZip,
      county: this.companyAddressCounty,
      country_id: this.companyAddressCountryId,
      country: this.companyAddressCountry,
      default: null,
      subscriber_id: this.subscriberId,
    });
  }

  company: Company;
  bankName: string = '';
  bankBic: string = '';
  bankAccountNumber: string = '';
  relationId: number = 0;
  relationName: string = '';
  relationVatNumber: string = '';
  relationAddressStreet: string = '';
  relationAddressNumber: string = '';
  relationAddressZip: string = '';
  relationAddressCity: string = '';
  relationAddressCounty: string = '';
  relationAddressCountryId: number = 0;
  relationAddressCountry: Country;

  get customer_address(): Address {
    return new Address({
      id: null,
      street: this.relationAddressStreet,
      number: this.relationAddressNumber,
      city: this.relationAddressCity,
      zip: this.relationAddressZip,
      county: this.relationAddressCounty,
      country_id: this.relationAddressCountryId,
      country: this.relationAddressCountry,
      default: null,
      subscriber_id: this.subscriberId,
    });
  }

  relation: Relation;
  isProspect: boolean = false;
  value: number = 0;
  vatValue: number = 0;
  valueIncl: number = 0;
  intnote: string = '';
  note: string = '';
  date: string = '';
  private _date: Date;

  get Date() {return this._date;}

  set Date(date: Date) {
    this._date = date;
    this.date = SetDateScalar(date);
  }

  enddate: string = '';
  private _enddate: Date;

  get Enddate() {return this._enddate;}

  set Enddate(date: Date) {
    this._enddate = date;
    this.enddate = SetDateScalar(date);
  }

  status: InvoiceStatusEnum = InvoiceStatusEnum.CONCEPT;

  get Status(): string {
    switch (this.status) {
      case InvoiceStatusEnum.CONCEPT:
        return translate(marker('label.invoice.status.concept', 'Concept'));
      case InvoiceStatusEnum.UNPAID:
        return translate(marker('label.invoice.status.unpaid', 'Onbetaald'));
      case InvoiceStatusEnum.PARTIALLYPAID:
        return translate(marker('label.invoice.status.partiallyPaid', 'Gedeeltelijk betaald'));
      case InvoiceStatusEnum.PAID:
        return translate(marker('label.invoice.status.paid', 'Betaald'));
    }
  }

  paymentReference: string = '';
  paymentReferenceStruct: boolean = false;
  created: string = "";
  private _created: Date;

  get Created() {return this._created;}

  set Created(date: Date) {
    this._created = date;
    this.created = SetDateTimeScalar(date);
  }

  updated: string = "";
  private _updated: Date;

  get Updated() {return this._updated;}

  set Updated(date: Date) {
    this._updated = date;
    this.updated = SetDateTimeScalar(date);
  }

  journalId: number = 0;
  journal: Journal;
  userId: number = 0;
  userName: string = '';
  userPhone: string = '';
  userMail: string = '';
  contactId: number = 0;
  contactName: string = '';
  contactPhone: string = '';
  contactMail: string = '';
  user: User;
  contact: Contact;
  reminderId: number = 0;
  subscriptionId: number = 0;
  subscription: Subscription;
  exported: string = '';
  private _exported: Date;

  get Exported() {return this._exported;}

  set Exported(date: Date) {
    this._exported = date;
    this.exported = SetDateTimeScalar(date);
  }

  exportedType: LedgerExportTypeEnum = LedgerExportTypeEnum.PDF;

  get ExportedType(): string {
    switch (this.exportedType) {
      case LedgerExportTypeEnum.PDF:
        return translate(marker('label.ledgerExportType.email', 'E-mail'));
      case LedgerExportTypeEnum.OCTOPUS:
        return translate(marker('label.ledgerExportType.octopus', 'Octopus'));
      case LedgerExportTypeEnum.ADMISOL:
        return translate(marker('label.ledgerExportType.admisol', 'Admisol'));
      case LedgerExportTypeEnum.YUKI:
        return translate(marker('label.ledgerExportType.yuki', 'Yuki'));
      case LedgerExportTypeEnum.MANUAL:
        return translate(marker('label.ledgerExportType.manual', 'Manueel gemarkeerd als geëxporteerd'));
      case LedgerExportTypeEnum.EXACT:
        return translate(marker('label.ledgerExportType.exactApi', 'Exact'));
    }
  }

  relationReference: string = '';
  guestToken: string = '';

  get GuestToken() {
    return `${getWhitelabelEnvironment().app.url}/guest/INV+${this.guestToken}`;
  }

  guestEnabled: boolean = false;
  vatCode: string = "";
  accountNumberId: number = 0;
  accountNumber: AccountNumber;

  sublines: InvoiceSubline[] = [];
  documents: Document[] = [];
  transactions: Transaction[] = [];
  emails: Email[] = [];
  peppol: Peppol[] = [];
  reminder: InvoiceReminder;
  translocations: Translocation[] = [];
  peppols: Peppol[] = [];
  workPerformances: WorkPerformance[] = [];
  parameters: InvoiceParameter[] = [];

  get Valid(): boolean {
    return (
      this.companyId != null &&
      this.companyId > 0 &&
      this.companyName != null &&
      this.companyName.trim().length > 0 &&
      this.companyVatNumber != null &&
      this.companyVatNumber.trim().length > 0 &&
      this.relationId != null &&
      this.relationId > 0 &&
      this.relationName != null &&
      this.relationName.trim().length > 0 &&
      this.isProspect !== null &&
      this.value !== null &&
      this.vatValue !== null &&
      this.valueIncl !== null &&
      this.date !== null &&
      this.date !== undefined &&
      this.enddate !== null &&
      this.enddate !== undefined
    );
  }

  canConfirm(): boolean {
    return (
      this.date &&
      moment(this.date)
        .isValid() &&
      (this.companyName ?? '').trim().length > 0 &&
      (this.companyVatNumber ?? '').length > 0 &&
      (this.relationId ?? 0) > 0 &&
      (this.relationName ?? '').trim().length > 0 &&
      this.value !== null &&
      this.sublines.length > 0 &&
      this.status == InvoiceStatusEnum.CONCEPT &&
      this.valueIncl !== null &&
      this.vatValue !== null &&
      !!this.sublines.find((subline) => !subline.Valid)
    );
  }

  constructor(invoice: IInvoice = null) {
    if (invoice === null) {
      return;
    }

    constructFromInterface(this, invoice);

    if (invoice.date) {this._date = GetDateScalar(invoice.date);}
    if (invoice.enddate) {this._enddate = GetDateScalar(invoice.enddate);}
    if (invoice.created) {this._created = GetDateTimeScalar(invoice.created);}
    if (invoice.updated) {this._updated = GetDateTimeScalar(invoice.updated);}
    if (invoice.exported) {this._exported = GetDateTimeScalar(invoice.exported);}
    if (invoice.companyAddressCountryId) {
      this.companyAddressCountry = new Country(countries.find(x => x.id == invoice.companyAddressCountryId));
    }
    if (invoice.company) {
      this.company = new Company(invoice.company);
    }
    if (invoice.relationAddressCountryId) {
      this.relationAddressCountry = new Country(countries.find(x => x.id == invoice.relationAddressCountryId));
    }
    if (invoice.relation) {
      this.relation = new Relation(invoice.relation);
    }
    if (invoice.subscription) {
      this.subscription = new Subscription(invoice.subscription);
    }
    if (invoice.reminder) {
      this.reminder = new InvoiceReminder(invoice.reminder);
    }
    if (invoice.user) {
      this.user = new User(invoice.user);
    }
    if (invoice.contact) {
      this.contact = new Contact(invoice.contact);
    }
    if (invoice.journal) {
      this.journal = new Journal(invoice.journal);
    }
    if (invoice.accountNumber) {
      this.accountNumber = new AccountNumber(invoice.accountNumber);
    }

    if (invoice.sublines?.length > 0) {
      this.sublines = invoice.sublines.map((x) => new InvoiceSubline(x));
    }
    if (invoice.documents?.length > 0) {
      this.documents = invoice.documents.map((x) => new Document(x));
    }
    if (invoice.transactions?.length > 0) {
      this.transactions = invoice.transactions.map((x) => new Transaction(x));
    }
    if (invoice.translocations?.length > 0) {
      this.translocations = invoice.translocations.map((x) => new Translocation(x));
    }
    if (invoice.emails?.length > 0) {
      this.emails = invoice.emails.map((x) => new Email(x));
    }
    if (invoice.workPerformances?.length > 0) {
      this.workPerformances = invoice.workPerformances.map((x) => new WorkPerformance(x));
    }
    if (invoice.peppols?.length > 0) {
      this.peppols = invoice.peppols.map((x) => new Peppol(x));
    }
    if (invoice.parameters?.length > 0) {
      this.parameters = invoice.parameters.map((x) => new InvoiceParameter(x));
    }
  }

  public calculateValue(): void {
    this.value = _.sumBy(this.sublines, (x) => +x.price);
    this.valueIncl = _.sumBy(this.sublines, (x) => +x.priceIncl);
    this.vatValue = _.sumBy(this.sublines, (x) => +x.vatprice);
  }

  public static new(): Invoice {
    return new Invoice({
      id: null,
      subscriberId: null,
      number: null,
      Number: null,
      orderId: null,
      quoteId: null,
      companyId: null,
      companyName: null,
      companyVatNumber: null,
      companyAddressStreet: null,
      companyAddressNumber: null,
      companyAddressZip: null,
      companyAddressCity: null,
      companyAddressCounty: null,
      companyAddressCountryId: null,
      companyAddressCountry: null,
      company: null,
      relationId: null,
      relationName: null,
      relationVatNumber: null,
      relationAddressStreet: null,
      relationAddressNumber: null,
      relationAddressZip: null,
      relationAddressCity: null,
      relationAddressCounty: null,
      relationAddressCountryId: null,
      relationAddressCountry: null,
      relation: null,
      value: 0,
      vatValue: 0,
      valueIncl: 0,
      intnote: null,
      note: null,
      date: SetDateScalar(new Date()),
      enddate: null,
      status: InvoiceStatusEnum.CONCEPT,
      paymentReference: null,
      paymentReferenceStruct: true,
      created: null,
      updated: null,
      sublines: [],
      documents: [],
      transactions: [],
      translocations: [],
      emails: [],
      peppols: [],
      workPerformances: [],
      parameters: [],
      bankAccountNumber: null,
      bankBic: null,
      bankName: null,
      journalId: null,
      journal: null,
      userId: null,
      contactId: null,
      userName: null,
      userPhone: null,
      userMail: null,
      contactName: null,
      contactPhone: null,
      contactMail: null,
      user: null,
      contact: null,
      reminderId: null,
      reminder: null,
      subscriptionId: null,
      subscription: null,
      exported: null,
      exportedType: null,
      relationReference: null,
      guestToken: null,
      guestEnabled: true,
      vatCode: null,
      accountNumberId: null,
      accountNumber: null,
    });
  }

  public convertToInput(): IInvoiceInput {
    return {
      id: this.id,
      orderId: this.orderId,
      quoteId: this.quoteId,
      companyId: this.companyId,
      companyName: this.companyName,
      companyVatNumber: this.companyVatNumber,
      companyAddressStreet: this.companyAddressStreet,
      companyAddressNumber: this.companyAddressNumber,
      companyAddressZip: this.companyAddressZip,
      companyAddressCity: this.companyAddressCity,
      companyAddressCounty: this.companyAddressCounty,
      companyAddressCountryId: this.companyAddressCountryId,
      relationId: this.relationId,
      relationName: this.relationName,
      relationVatNumber: this.relationVatNumber,
      relationAddressStreet: this.relationAddressStreet,
      relationAddressNumber: this.relationAddressNumber,
      relationAddressZip: this.relationAddressZip,
      relationAddressCity: this.relationAddressCity,
      relationAddressCounty: this.relationAddressCounty,
      relationAddressCountryId: this.relationAddressCountryId,
      intnote: this.intnote,
      note: this.note,
      date: SetDateScalar(this.date ? moment(this.date)
        .toDate() : null),
      enddate: SetDateScalar(this.enddate ? moment(this.enddate)
        .toDate() : null),
      status: this.status,
      paymentReference: this.paymentReference,
      paymentReferenceStruct: this.paymentReferenceStruct,
      value: this.value,
      vatValue: this.vatValue,
      valueIncl: this.valueIncl,
      bankAccountNumber: this.bankAccountNumber,
      bankBic: this.bankBic,
      bankName: this.bankName,
      journalId: +this.journalId,
      userId: this.userId ? +this.userId : null,
      contactId: this.contactId ? +this.contactId : null,
      userName: this.userName,
      userPhone: this.userPhone,
      userMail: this.userMail,
      contactName: this.contactName,
      contactPhone: this.contactPhone,
      contactMail: this.contactMail,
      relationReference: (this.relationReference ?? '').trim().length > 0 ? '' + this.relationReference : null,
      guestEnabled: !!this.guestEnabled,
      vatCode: this.vatCode ? "" + this.vatCode : null,
      accountNumberId: this.accountNumberId ? +this.accountNumberId : null,
    };
  }
}

export class InvoiceSubline implements IInvoiceSubline {
  id: number = 0;
  subscriberId: number = 0;
  invoiceId: number = 0;
  invoice: Invoice;

  articleTypeId: number = 0;

  get ArtType(): string {
    switch (this.articleTypeId) {
      case ArticleTypeEnum.PRODUCT:
        return translate(marker('label.article.product', 'product'));
      case ArticleTypeEnum.SERVICE:
        return translate(marker('label.article.service', 'Dienst'));
      case ArticleTypeEnum.COMBINEDARTICLE:
        return translate(marker('label.article.combinedArticle', 'Gecombineerd artikel'));
    }
  }

  get ArtTypeButton(): string {
    switch (this.articleTypeId) {
      case ArticleTypeEnum.PRODUCT:
        return translate(marker('label.article.product', 'product'));
      case ArticleTypeEnum.SERVICE:
        return translate(marker('label.article.service', 'Dienst'));
      case ArticleTypeEnum.COMBINEDARTICLE:
        return translate(marker('label.article.combinedArticle', 'Gecombineerd artikel'));
    }
  }

  get ArtTypeSku(): string {
    switch (this.articleTypeId) {
      case ArticleTypeEnum.PRODUCT:
        return translate(marker('label.product.sku', 'Sku'));
      case ArticleTypeEnum.SERVICE:
        return translate(marker('label.service.sku', 'Sku'))
      case ArticleTypeEnum.COMBINEDARTICLE:
        return translate(marker('label.combinedArticle.sku', 'Sku'))
    }
  }

  get ArtTypeName(): string {
    switch (this.articleTypeId) {
      case ArticleTypeEnum.PRODUCT:
        return translate(marker('label.product.name', 'Naam'));
      case ArticleTypeEnum.SERVICE:
        return translate(marker('label.service.name', 'Naam'))
      case ArticleTypeEnum.COMBINEDARTICLE:
        return translate(marker('label.combinedArticle.name', 'Naam'))
    }
  }

  articleId: number = 0;
  articleSku: string = '';
  articleName: string = '';
  articleSerialNumber: string = '';
  articleWarranty: number = 0;

  get Warranty(): string {
    return this.articleWarranty === null || this.articleWarranty === undefined ? null : `${this.articleWarranty} ${translate(marker('label.warranty.months', 'Maanden'))}`;
  }

  article: Service | CombinedArticle | Product;
  vatCode: string = "";
  accountNumberId: number = 0;
  accountNumber: AccountNumber;
  amount: number = 0;
  unitPrice: number = 0;
  basePrice: number = 0;
  discount: number = 0;
  price: number = 0;
  priceIncl: number = 0;
  vatprice: number = 0;
  note: string = '';
  created: string = "";
  private _created: Date;

  get Created() {return this._created;}

  set Created(date: Date) {
    this._created = date;
    this.created = SetDateTimeScalar(date);
  }

  updated: string = "";
  private _updated: Date;

  get Updated() {return this._updated;}

  set Updated(date: Date) {
    this._updated = date;
    this.updated = SetDateTimeScalar(date);
  }

  order: number = 0;
  hideOnPdf: boolean = false;
  hidePriceOnPdf: boolean = false;
  unit: string = '';

  workperformanceId: number = 0;
  workperformance: WorkPerformance;

  get Valid(): boolean {
    return (
      this.articleTypeId != null &&
      (this.articleName ?? '').trim().length > 0 &&
      this.amount !== null &&
      this.unitPrice !== null &&
      this.basePrice !== null &&
      this.discount !== null &&
      this.price !== null &&
      !_.isNil(this.vatCode)
    );
  }

  get VatName() {
    return VatService.getLabel(this.vatCode);
  }

  constructor(subline: IInvoiceSubline = null) {
    if (subline === null) {
      return;
    }

    constructFromInterface(this, subline);

    if (subline.created) {this._created = GetDateTimeScalar(subline.created);}
    if (subline.updated) {this._updated = GetDateTimeScalar(subline.updated);}
    if (subline.invoice) {
      this.invoice = new Invoice(subline.invoice);
    }
    if (subline.article) {
      switch (subline?.articleTypeId) {
        case ArticleTypeEnum.PRODUCT:
          this.article = new Product(subline.article as IProduct);
          break;
        case ArticleTypeEnum.SERVICE:
          this.article = new Service(subline.article as IService);
          break;
        case ArticleTypeEnum.COMBINEDARTICLE:
          this.article = new CombinedArticle(subline.article as ICombinedArticle);
          break;
      }
    }

    if (subline.accountNumber) {
      this.accountNumber = new AccountNumber(subline.accountNumber);
    }
    if (subline.workperformance) {
      this.workperformance = new WorkPerformance(subline.workperformance);
    }
  }

  public static new(): InvoiceSubline {
    return new InvoiceSubline({
      id: null,
      subscriberId: null,
      invoiceId: null,
      invoice: null,
      articleTypeId: ArticleTypeEnum.PRODUCT,
      articleId: null,
      articleSku: null,
      articleName: null,
      articleWarranty: null,
      articleSerialNumber: null,
      article: null,
      vatCode: null,
      accountNumber: null,
      accountNumberId: null,
      amount: 1,
      unitPrice: 0,
      basePrice: 0,
      discount: 0,
      price: 0,
      priceIncl: 0,
      vatprice: 0,
      note: null,
      created: null,
      updated: null,
      order: null,
      hideOnPdf: false,
      hidePriceOnPdf: false,
      unit: null,
      workperformance: null,
      workperformanceId: null,
    });
  }

  public convertToInput(): IInvoiceSublineInput {
    return {
      id: !_.isNil(this.id) ? +this.id : null,
      articleTypeId: !_.isNil(this.articleTypeId) ? +this.articleTypeId : null,
      articleId: !_.isNil(this.articleId) ? +this.articleId : null,
      articleName: !_.isNil(this.articleName) && (this.articleName ?? '').trim().length > 0 ? '' + this.articleName : null,
      articleSerialNumber: !_.isNil(this.articleSerialNumber) && (this.articleSerialNumber ?? '').trim().length > 0 ? '' + this.articleSerialNumber : null,
      articleSku: !_.isNil(this.articleSku) && (this.articleSku ?? '').trim().length > 0 ? '' + this.articleSku : null,
      articleWarranty: !_.isNil(this.articleWarranty) ? +this.articleWarranty : null,
      vatCode: !_.isNil(this.vatCode) ? "" + this.vatCode : null,
      amount: !_.isNil(this.amount) ? +this.amount : null,
      discount: !_.isNil(this.discount) ? +this.discount : null,
      price: !_.isNil(this.price) ? +this.price : null,
      basePrice: !_.isNil(this.basePrice) ? +this.basePrice : null,
      priceIncl: !_.isNil(this.priceIncl) ? +this.priceIncl : null,
      unitPrice: !_.isNil(this.unitPrice) ? +this.unitPrice : null,
      vatprice: !_.isNil(this.vatprice) ? +this.vatprice : null,
      note: !_.isNil(this.note) && (this.note ?? '').trim().length > 0 ? '' + this.note : null,
      accountNumberId: !_.isNil(this.accountNumberId) ? +this.accountNumberId : null,
      order: !_.isNil(this.order) ? +this.order : null,
      hideOnPdf: !_.isNil(this.hideOnPdf) ? !!this.hideOnPdf : null,
      hidePriceOnPdf: !_.isNil(this.hidePriceOnPdf) ? !!this.hidePriceOnPdf : null,
      unit: !_.isNil(this.unit) && (this.unit ?? '').trim().length > 0 ? '' + this.unit : null,
      workperformanceId: !_.isNil(this.workperformanceId) ? +this.workperformanceId : null,
    };
  }

  public checkPdfSettings() {
      if (this.priceIncl !== 0) {
        this.hideOnPdf = false;
        this.hidePriceOnPdf = false;
      }
  }

  public calculateSublineValuesFomUnitPrice() {
    const vat = VatService.Get(this.vatCode);
    this.basePrice = +(+this.unitPrice * +(this.amount ?? 0)).toFixed(2);
    this.price = +(+this.basePrice - +(this.discount ?? 0)).toFixed(2);
    this.vatprice = +(vat.percentage ? +this.price * (+vat.percentage / 100) : 0).toFixed(2);
    this.priceIncl = +(+this.price + +(this.vatprice ?? 0)).toFixed(2);

    this.checkPdfSettings();
  }

  public calculateSublineValuesFromAmount() {
    this.price = +(this.price ?? 0);
    this.priceIncl = +(this.priceIncl ?? 0);
    this.vatprice = +(this.vatprice ?? 0);
    this.unitPrice = +(this.unitPrice ?? 0);
    this.amount = +(this.amount ?? 1);

    const vat = VatService.Get(this.vatCode);
    this.unitPrice = this.unitPrice ?? 0;
    this.basePrice = +(+this.unitPrice * +(this.amount ?? 0)).toFixed(2);
    this.price = +(+this.basePrice - +(this.discount ?? 0)).toFixed(2);
    this.vatprice = +(vat.percentage ? +this.price * (+vat.percentage / 100) : 0).toFixed(2);
    this.priceIncl = +(+this.price + +(this.vatprice ?? 0)).toFixed(2);

    this.checkPdfSettings();
  }

  public calculateSublineValuesFromBaseSalesPrice() {
    this.price = +(this.price ?? 0);
    this.priceIncl = +(this.priceIncl ?? 0);
    this.vatprice = +(this.vatprice ?? 0);
    this.unitPrice = +(this.unitPrice ?? 0);
    this.amount = +(this.amount ?? 1);

    const vat = VatService.Get(this.vatCode);
    this.unitPrice = +(+(this.amount ?? 0) == 0 ? 0 : +this.basePrice / +this.amount).toFixed(2);
    this.price = +(+this.basePrice - +(this.discount ?? 0)).toFixed(2);
    this.vatprice = +(vat.percentage ? +this.price * (+vat.percentage / 100) : 0).toFixed(2);
    this.priceIncl = +(+this.price + +(this.vatprice ?? 0)).toFixed(2);

    this.checkPdfSettings();
  }

  public calculateSublineValuesFromDiscount() {
    this.price = +(this.price ?? 0);
    this.priceIncl = +(this.priceIncl ?? 0);
    this.vatprice = +(this.vatprice ?? 0);
    this.unitPrice = +(this.unitPrice ?? 0);
    this.amount = +(this.amount ?? 1);

    const vat = VatService.Get(this.vatCode);
    this.price = +(+this.basePrice - +(this.discount ?? 0)).toFixed(2);
    this.vatprice = +(vat.percentage ? +this.price * (+vat.percentage / 100) : 0).toFixed(2);
    this.priceIncl = +(+this.price + +(this.vatprice ?? 0)).toFixed(2);

    this.checkPdfSettings();
  }

  public calculateSublineValuesFromSalesPrice() {
    this.priceIncl = +(this.priceIncl ?? 0);
    this.vatprice = +(this.vatprice ?? 0);
    this.unitPrice = +(this.unitPrice ?? 0);
    this.amount = +(this.amount ?? 1);

    const vat = VatService.Get(this.vatCode);
    this.basePrice = +(+this.price + +(this.discount ?? 0)).toFixed(2);
    this.unitPrice = +(+(this.amount ?? 0) == 0 ? 0 : +this.basePrice / +this.amount).toFixed(2);
    this.vatprice = +(vat.percentage ? +this.price * (+vat.percentage / 100) : 0).toFixed(2);
    this.priceIncl = +(+this.price + +(this.vatprice ?? 0)).toFixed(2);
    if (this.priceIncl !== 0) {
      this.hideOnPdf = false;
    }

    this.checkPdfSettings();
  }

  public calculateSublineValuesFromSalesPriceIncl() {
    this.price = +(this.price ?? 0);
    this.vatprice = +(this.vatprice ?? 0);
    this.basePrice = +(this.basePrice ?? 0);
    this.unitPrice = +(this.unitPrice ?? 0);
    this.amount = +(this.amount ?? 1);
    this.discount = +(this.discount ?? 0);

    const vat = VatService.Get(this.vatCode);
    this.price = +(vat.percentage ? +this.priceIncl / (+vat.percentage / 100 + 1) : +this.priceIncl).toFixed(2);
    this.vatprice = +(vat.percentage ? +this.price * (+vat.percentage / 100) : 0).toFixed(2);
    this.basePrice = +(+this.price + +(this.discount ?? 0)).toFixed(2);
    this.unitPrice = +(+(this.amount ?? 0) == 0 ? 0 : +this.basePrice / +this.amount).toFixed(2);

    this.checkPdfSettings();
  }
}

export enum InvoiceStatusEnum {
  CONCEPT = 1,
  UNPAID = 2,
  PARTIALLYPAID = 3,
  PAID = 4,
}

export enum LinePriceOverviewEnum {
  EXCLVAT = 1,
  INCLVAT = 2,
  UNITPRICE = 3,
}

export class InvoiceParameter implements IInvoiceParameter {
  id: number = 0;
  subscriberId: number = 0;
  invoiceId: number = 0;
  key: string = "";
  value: string = "";

  constructor(parameter: IInvoiceParameter = null) {
    if (parameter === null) {
      return;
    }

    constructFromInterface(this, parameter);
  }

  convertToInput() {
    return {
      id: this.id ? +this.id : null,
      key: (this.key ?? '').length > 0 ? '' + this.key : null,
      value: (this.value ?? '').length > 0 ? '' + this.value : null,
    } as IInvoiceParameterInput;
  }

  public static new() {
    return new InvoiceParameter({
      id: null,
      invoiceId: null,
      key: '',
      subscriberId: null,
      value: '',
    });
  }
}
